<template>
  <div>
    <div class="row mb-3">
      <div class="col-12 text-right">
        <b-button variant="primary" class=""
          @click.prevent="popupModalManage = true" type="button">
        <span class=""> Manage Addons</span></b-button>
      </div>
    </div>
    <div class="row">
      <div class="col-md-6 mb-2" v-for="(addon, index) in product.addons" :key="index">
        <div role="tablist" class="">
          <b-card no-body  class="mb-1 shadow-none" >
            <b-card-header header-tag="header" class="bg-transparent" role="tab">
              <div class="d-sm-flex">
                <h5 class="m-0 flex-grow-1 d-flex align-items-center">
                  <a v-b-toggle="`addonAccordion-${index}`" class="text-dark" href="javascript: void(0);">
                    <i class="mdi mdi-check-circle mr-1 text-primary"></i>{{addon.name}} - ${{addon.charge}}
                  </a>
                </h5>
              </div>
            </b-card-header>
            <b-collapse :id="`addonAccordion-${index}`" accordion="accordion" role="tabpanel">
              <b-card-body>
                <h6>Charge: ${{addon.charge}}</h6>
                <b-card-text>Duration: {{calculateDeliveryTime(addon.duration)}}</b-card-text>
                <b-card-text>{{addon.description}}</b-card-text>
              </b-card-body>
            </b-collapse>
          </b-card>
        </div>
      </div>
    </div>
    <b-modal v-model="popupModalManage" hide-footer modal-class="modal-fullscreen"
      title="Select addons for this product" body-class="loading-viewport"
      title-class="font-18" @hide="initializeAll()">
      <div>
        <product-addon-form if="popupModalManage" @closeMe="initializeAll()"
          :editItem="product" />
      </div>
    </b-modal>
  </div>

</template>

<script>
import ProductAddonForm from '@/components/forms/ProductAddonForm.vue';

export default {
  components: { ProductAddonForm },
  data(){
    return{
      popupModalManage: false,
    }
  },
  computed: {
    product(){
      return this.$store.state.productList.product;
    },
  },
  methods:{
    initializeAll(){
      this.popupModalManage = false;
    },
  }
}
</script>

<style>

</style>