<template>
  <div>
    <div class="mb-3 bg-light d-flex align-items-center justify-content-between p-2">
      <h5 class="my-0">
        <i class="mdi mdi-playlist-edit mr-1"></i> Requirement Sections
      </h5>
    </div>
    <div class="row mb-3">
      <div class="col-12 text-right">
        <b-button variant="primary" class=""
          @click.prevent="popupModalManage = true" type="button">
        <span class=""> Manage Sections</span></b-button>
      </div>
    </div>
    <div class="row">
      <div class="col-md-6 mb-2" v-for="(section, index) in product.requirement_sections" :key="index">
        <router-link :to="`/requirement-sections/${section.id}`">
          <div class="list-group-item d-flex align-items-center">
            <span class="font-weight-bolder">
            <i class="mdi mdi-bookmark-check-outline mr-1"></i>{{section.nickname}}</span>
          </div>
        </router-link>
      </div>
    </div>
    <b-modal v-model="popupModalManage" hide-footer modal-class="modal-fullscreen"
      title="Select requirement sections for this product" body-class="loading-viewport"
      title-class="font-18" @hide="initializeAll()">
      <div>
        <product-section-form if="popupModalManage" @closeMe="initializeAll()"
          :editItem="product" />
      </div>
    </b-modal>
  </div>

</template>

<script>
import ProductSectionForm from '@/components/forms/ProductSectionForm.vue';

export default {
  components: { ProductSectionForm },
  data(){
    return{
      popupModalManage: false,
    }
  },
  computed: {
    product(){
      return this.$store.state.productList.product;
    },
  },
  methods:{
    initializeAll(){
      this.popupModalManage = false;
    },
  }
}
</script>

<style>

</style>