<template>
    <form class="">
        <is-loading v-if="isLoading" :box="true" />
        <div class="form-group mb-3">
          <label>Select Attributes</label>
            <multiselect :options="attributes"
              searchable
              mode="tags"
              valueProp="id"
              label="name" track-by="id"
              :multiple="true"
              :hide-selected="true"
              :close-on-select="false"
              v-model="data.items" placeholder="--select attributes--"></multiselect>
        </div>
        <div class="d-flex mt-3 justify-content-end">
          <b-button variant="primary" @click.prevent="save()" type="button"> Submit </b-button>
        </div>
    </form>
</template>

<script>
import IsLoading from '@/components/IsLoading.vue'
import Multiselect from 'vue-multiselect'

export default {
    components:{ IsLoading, Multiselect},
    props:{
        editItem:{
            type: Object,
            default: null
        },
    },
    data(){
        return{
            isLoading: false,
            data: {
                items: [],
            },
        }
    },
    computed:{
        attributes(){
            return this.$store.state.attribute.list.map(i => {
                return {
                    id: i.id, name: `${i.name} - (${i.type})`
                }
            });
        },
    },
    methods:{
        save() {
            this.$validator.validateAll().then(result => {
                if (!result) {this.alertError("Form not valid"); return }
                const formData = new FormData()
                formData.append('sync_item', 'attributes')
                formData.append('data', JSON.stringify(this.data))
                this.$store.dispatch("changeLoaderValue", true)
                this.$http.post(`/products/${this.editItem.id}/sycn-relations`, formData)
                .then((response) => {
                    this.$store.dispatch("changeLoaderValue", false)
                    if(response.data.success){
                        this.$store.commit("productList/UPDATE_PRODUCT", response.data.data)
                        this.resetForm();
                    }
                })
            })
        },
        resetForm(){
            this.$validator.reset()
            this.$emit("closeMe")
        },
        getOptionData(){
            this.isLoading = true;
            this.$store.dispatch('attribute/fetchAllItems', { model: "PRODUCT" })
            .then(response => this.isLoading = false );
        },
    },
    mounted(){
        this.getOptionData()
        this.data.items = this.editItem.attributes.map(i => {
            return {  id: i.id, name: `${i.name} - (${i.type})` }
        });
    }
}
</script>

<style>

</style>