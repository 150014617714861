<template>
<div>
    <h6>Product Banner</h6>
    <p>{{product.banner ? absoluteUrl(product.banner) : 'no file to show'}}</p>
    <b-button variant="light" class="mt-2 d-block" size="sm" @click.prevent="popupUpdateBanner = true"><i class="mdi mdi-camera-enhance-outline mr-1"></i>Upate Banner</b-button>
    <b-modal v-model="popupUpdateBanner" hide-footer centered title="Update Product Image" title-class="font-18">
      <vue-dropzone id="productIconUpdateDrop" ref="productIconUpdateDrop"
      :use-custom-slot="true" :maxFiles="1">
        <div class="dneedsclick m-0">
          <i class="h1 text-muted ri-upload-cloud-2-line"></i>
          <h4>Drop files here or click to upload.</h4>
          <span class="text-muted font-13">
           (Each File should not be more than 2MB)
          </span>
        </div>
      </vue-dropzone>
      <div class="d-flex mt-3 justify-content-end">
        <b-button class="mr-1"  @click.prevent="popupUpdateBanner = false">Close</b-button>
        <b-button variant="primary" @click.prevent="updateProduct" type="button">Upload</b-button>
      </div>
    </b-modal>
</div>
</template>

<script>
import VueDropzone from '@/components/VueDropzone.vue';

export default {
  components: { 
    VueDropzone
  },
  name: "product-icon",
  data() {
    return {
      popupUpdateBanner:false,
    }
  },
  computed:{
    product(){
      return this.$store.state.productList.product;
    }
  },
  methods: {
    updateProduct() {
      if(!this.collectFiles().length){ this.alertError("Image file is required"); return;}
      const formData = new FormData()
      formData.append('image_file', this.collectFiles()[0])
      this.$store.dispatch("changeLoaderValue", true)
      this.$http.post(`/products/${this.product.id}/update-banner`,formData)
        .then((response)=> {
        this.$store.dispatch("changeLoaderValue")
        if(response.data.success){
          this.removeFiles()
          this.$store.commit("productList/UPDATE_PRODUCT", response.data.data)
        }
      })
    },
    collectFiles(){
      var arrafile = [];
      if(this.$refs.productIconUpdateDrop){
        arrafile = [...this.$refs.productIconUpdateDrop.getAcceptedFiles()];
      }
      return arrafile
    },
    removeFiles() {
      this.$refs.productIconUpdateDrop.removeAllFiles();
      this.popupUpdateBanner = false
    },
  }
}

</script>

