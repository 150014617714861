<template>
  <div>
    <div class="row mb-3">
      <div class="col-12 text-right">
        <b-button variant="primary" class=""
          @click.prevent="popupModalManage = true" type="button">
        <span class=""> Manage Attributes</span></b-button>
      </div>
    </div>
    <div class="row">
      <div class="col-md-6 mb-2" v-for="(attribute, index) in product.attributes" :key="index">
        <div class="list-group-item d-flex align-items-center
          justify-content-between">
          <span class="font-weight-bolder">
            <i class="mdi mdi-bookmark-check-outline mr-1"></i>{{attribute.name}} -  {{attribute.type}}</span>
        </div>
      </div>
    </div>
    <b-modal v-model="popupModalManage" hide-footer modal-class="modal-fullscreen"
      title="Select attributes for this product" body-class="loading-viewport"
      title-class="font-18" @hide="initializeAll()">
      <div>
        <product-attribute-form if="popupModalManage" @closeMe="initializeAll()"
          :editItem="product" />
      </div>
    </b-modal>
  </div>

</template>

<script>
import ProductAttributeForm from '@/components/forms/ProductAttributeForm.vue';

export default {
  components: { ProductAttributeForm },
  data(){
    return{
      popupModalManage: false,
    }
  },
  computed: {
    product(){
      return this.$store.state.productList.product;
    },
  },
  methods:{
    initializeAll(){
      this.popupModalManage = false;
    },
  }
}
</script>

<style>

</style>