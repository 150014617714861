<template>
    <div>
        <product-addons />
        <hr>
        <product-questions />
        <hr>
        <requirement-sections />
    </div>
</template>
<script>
import ProductAddons from "./ProductAddons.vue"
import ProductQuestions from "./ProductQuestions.vue"
import RequirementSections from "./RequirementSections.vue"

export default {
    components:{
        ProductAddons,
        ProductQuestions,
        RequirementSections
    }
}
</script>
